


























































import { defineComponent, onMounted, provide, ref } from "@vue/composition-api";
import { getPayouts } from "@/api/payoutService";
import { userModule } from "@/store/modules/user";
import KeyNumbers from "@/components/payouts/KeyNumbers.vue";
import Learnlink from "@learnlink/interfaces";
import PayoutCard from "@/components/payouts/PayoutCard.vue";
import PayoutsTable from "@/components/payouts/PayoutsTable.vue";
import useAsyncData from "@/hooks/useAsyncData";
import vuetify from "@/plugins/vuetify";
export const SHOW_INVOICE = "show_invoice";

export default defineComponent({
  name: "Payouts",
  components: { PayoutCard, PayoutsTable, KeyNumbers },
  setup: () => {
    const breakpoints = vuetify.framework.breakpoint;
    const payoutDialogVisible = ref(false);
    const selectedPayout = ref<Learnlink.Payout.Payout | null>(null);

    const { isLoading, data: payouts, hasError } = useAsyncData(
      () => getPayouts(userModule.state.userId),
    );
    const showPayout = (payout: Learnlink.Payout.Payout | null) => {
      selectedPayout.value = payout;
      payoutDialogVisible.value = payout !== null;
    };
    provide(SHOW_INVOICE, showPayout);

    return {
      breakpoints,
      hasError,
      payoutDialogVisible,
      payouts,
      isLoading,
      onMounted,
      selectedPayout,
    };
  },
});
