















import { defineComponent, PropType } from "@vue/composition-api";
import { PayoutTableHeaders } from "@/components/payouts/PayoutTableHeaders";
import Learnlink from "@learnlink/interfaces";
import PayoutsTableRow from "./PayoutsTableRow.vue";

export default defineComponent({
  name: "PayoutsTable",
  components: { PayoutsTableRow },
  props: {
    payouts: {
      type: Array as PropType<Learnlink.Payout.Payout[]>,
      default: () => [],
    },
  },
  setup: () => {
    return {
      headers: PayoutTableHeaders,
    };
  },
});
